.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: auto;
  padding: 5em;
  padding-top: 10em;
  max-width: 900px;
}

@media (min-width: 1024px) {
  .container {
    /* flex-direction: row; */
    padding-top: 10em;
    justify-content: space-between;
    gap: 2rem;
  }
}
.privacy p {
  padding-bottom: 20px;
}
.privacy .card {
  width: 100%;
  max-width: 768px;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  padding: 5em;
  border-width: 1px;
  border-radius: 0.375rem;
  border-color: rgba(229, 231, 235, 0.5);
  box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0 0 0 / 0.1),
    0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.privacy a {
  color: rgb(51, 88, 212);
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.privacy ul {
  padding-left: 20px;
  padding-top: 1em;
}
.privacy li {
  padding-bottom: 5px;
}
.privacy h2 {
  font-size: 1.4em;
  font-weight: 700;
  text-align: center;
  color: rgb(51, 88, 212);
  padding: 1em 0 2em 0;
}
.privacy h3 {
  padding: 0.5em 0em;
  font-size: 1.25em;
  font-weight: 600;
  color: rgb(5, 9, 21);
}
